<template>
  <div class="samples-index width-100">
    <Filters
      :filters="samplesFilters"
      filter-key="samples"
      :initial-data="filtersStatus.samples"
    />
    <CreateSample
      v-if="openCreateSampleModal"
      :rename="justRename"
      :order="currentSampleOrder"
      :is-modal-edit="isModalEdit"
      :initial-data="initialSampleData"
      @dialogClose="handleSampleDialogClose"
    />
    <UploadReport
      :visible="openUploadReportModal"
      :order="currentSampleOrder"
      :is-modal-edit="isModalEdit"
      :initial-data="initialSampleReportData"
      @dialogClose="handleUploadDialogClose"
    />
    <TableWrapper
      :table-columns="samplesColumns"
      table-key="samples"
      :enable-selection="canUse(['admin', 'samplesStatusManage'], true)"
      :massive-actions-options="massiveActionsOptions"
      @editArticle="editArticle"
      @disableSample="(row) => changeSampleStatus(row, false)"
      @enableSample="(row) => changeSampleStatus(row, true)"
      @selectionChange="selectionChange"
      @showAnalyses="goToOrderSampleAnalyses"
      @addAnalyses="goToOrderAddAnalyses"
      @editSample="editSample"
      @renameSample="renameSample"
      @deleteSample="deleteSample"
      @uploadSampleReport="uploadSampleReport"
      @downloadSampleReport="downloadSampleReport"
      @collapseItemClick="downloadSampleReport"
      @massiveAction="bulkApplyStatus"
    />
  </div>
</template>

<script>
import fileSaver from 'file-saver';
import { mapGetters, mapActions } from 'vuex';
import { samplesColumns, samplesFilters } from '@/config';
import { samplesApi, ordersApi } from '@/api';
import { SAMPLE_STATUSES_DESC, SAMPLE_STATUSES } from '@/config/constants';
import lib from '@/lib/functions';
import acl from '@/services/acl';
import TableWrapper from '@/components/TableWrapper.vue';
import CreateSample from '@/views/orders/CreateSample.vue';
import UploadReport from '@/views/orders/UploadReport.vue';
import Filters from '@/components/Filters.vue';

export default {
  name: 'Samples',
  components: {
    TableWrapper,
    Filters,
    CreateSample,
    UploadReport,
  },
  data() {
    return {
      samplesColumns,
      samplesFilters,
      selectedSamples: [],
      openCreateSampleModal: false,
      openUploadReportModal: false,
      currentSampleOrder: {},
      initialSampleData: {},
      initialSampleReportData: {
        microbiological: null,
        chemical: null,
        vitro: null,
      },
      isModalEdit: true,
      justRename: false,
      massiveActionsOptions: Object.keys(SAMPLE_STATUSES_DESC)
        .filter(s => s !== SAMPLE_STATUSES.REJECTED.toString())
        .map(key => ({ label: `enums.sampleStatuses.${SAMPLE_STATUSES_DESC[key]}`, value: key })),
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
      'currentRoute',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name === 'uploadSampleReport') {
        vm.getSampleReport();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'applyFilters',
      'parseQueryStringStatus',
    ]),
    async tableRows() {
      try {
        await lib.tableRows('samples', samplesApi, this.tableFilters('samples'),
          this.filtersStatus.samples, this.updateTableRows, this.updateTableCount);
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async deleteSample(row) {
      try {
        this.$confirm(this.$t('samples.delete.confirm'), this.$t('global.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(async () => {
            await ordersApi.deleteSample(row.orderId, row.id);
            this.$notify({ showClose: true, type: 'success', message: this.$t('samples.delete.success') });
            await this.tableRows();
          })
          .catch(() => {
            this.$notify({
              type: 'info',
              message: this.$t('samples.delete.cancelled'),
            });
          });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    editSample(row, justRename = false) {
      this.$router.push({
        name: 'editSample',
        params: { orderId: row.orderId, sampleId: row.id },
      });
      this.justRename = justRename;
      this.openCreateSampleModal = true;
      this.initialSampleData = row;
    },
    uploadSampleReport(row) {
      this.$router.push({
        name: 'uploadSampleReport',
        params: { orderId: row.orderId, sampleId: row.id },
      });
    },
    async downloadSampleReport(row, report = null) {
      try {
        this.loading = true;
        let file;
        if (report) {
          file = report.id
            ? await samplesApi.downloadSampleReport(row.id, report.id)
            : await samplesApi.downloadSamplePreviewReport(row.id);
        } else {
          file = await samplesApi.downloadSampleReport(row.id, row.reports[0].id);
        }
        if (file.data) {
          if (report && !report.id) {
            const cdArr = file.headers['content-disposition'].split(';');
            const filename = cdArr[1].split('=')[1];
            return fileSaver.saveAs(URL.createObjectURL(file.data), filename || `preview-${row.labSampleCode}.xlsx`);
          }
          return fileSaver.saveAs(URL.createObjectURL(file.data), `report-${row.reports[0].id}.pdf`);
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        return error;
      }
    },
    renameSample(row) {
      this.editSample(row, true);
    },
    goToOrderSampleAnalyses(row) {
      this.$router.push({
        name: 'orderSampleAnalysesIndex',
        params: { orderId: row.orderId, sampleId: row.id },
      });
    },
    handleSampleDialogClose(status) {
      this.openCreateSampleModal = false;
      if (status.success) {
        this.tableRows();
      }
      if (this.currentRoute.name === 'editSample') {
        this.$router.push({
          name: 'samplesIndex',
        });
      }
    },
    handleUploadDialogClose(status) {
      this.openUploadReportModal = false;
      if (status.success) {
        this.$router.push({
          name: 'samplesIndex',
        });
        this.tableRows();
      }
      if (this.currentRoute.name === 'uploadSampleReport') {
        this.$router.push({
          name: 'samplesIndex',
        });
      }
    },
    async bulkApplyStatus(massiveActionsValue) {
      try {
        if (this.selectedSamples.length) {
          const buildWhereCondition = selectedSamples => ({
            id: {
              inq: selectedSamples.map(sample => sample.id),
            },
          });
          await samplesApi
            .bulkUpdate({ status: parseInt(massiveActionsValue, 10) },
              buildWhereCondition(this.selectedSamples));
          this.tableRows();
        }
        this.$notify({ showClose: true, type: 'success', message: this.$t('samples.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    selectionChange(val) {
      this.selectedSamples = val;
    },
    editArticle(row) {
      this.$router.push({ name: 'sampleEdit', params: { sampleId: row.id } });
    },
    async changeSampleStatus(row, enabled) {
      try {
        await samplesApi.update({ enabled }, row.id);
        this.tableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('samples.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async getSampleReport() {
      const reports = await samplesApi.getSampleReports(this.$route.params.sampleId);
      if (reports && reports.data) {
        this.isModalEdit = true;
        this.initialSampleReportData.microbiological = reports.data.find(f => f.category === 'microbiological');
        this.initialSampleReportData.chemical = reports.data.find(f => f.category === 'chemical');
        this.initialSampleReportData.vitro = reports.data.find(f => f.category === 'vitro');
      }
      this.openUploadReportModal = true;
    },
    goToOrderAddAnalyses(row) {
      this.$router.push({
        name: 'editOrder',
        params: { orderId: row.orderId },
        query: { productId: row.id },
      });
    },
    canUse(permissions, checkAll = false) {
      return acl.userCanUse(permissions, checkAll);
    },
  },
};
</script>
<style lang="scss">
.disable-selected {
  margin-top: 1rem;
}
.samples-index {

  .el-table__body tr:hover > td.table-cell-status {
    @each $modifier, $map in $sample-statuses {
      &--#{$modifier} {
        filter: brightness(0.9);
        background-color: map-get($map, 'background');
      }
    }
  }

  .table-cell-status {
    @each $modifier, $map in $sample-statuses {
      &--#{$modifier} {
        background-color: map-get($map, 'background');
        color: map-get($map, 'color');
      }
    }
  }
}
</style>
