<template>
  <el-dialog
    :visible="visible"
    width="40%"
    @close="abortUpload"
  >
    <ContentHeader
      slot="title"
      :title="$t('orders.samples.uploadReport')"
    />
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <el-form
        ref="form"
        label-position="top"
      >
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="8">
            <FormItem
              :label="$t('enums.commodityCategory.microbiological')"
            >
              <el-button
                v-if="attachedFiles.microbiological"
                size="small"
                type="primary"
                @click="removeFileAssociation(attachedFiles.microbiological)"
              >
                {{ $t('global.removeSwitchPdf') }}
              </el-button>
              <el-upload
                v-else
                ref="microbiologicalUpload"
                action=""
                name="microbiological"
                accept="application/pdf"
                :limit="1"
                :on-change="handleChangeMicrobiological"
                :on-remove="handleRemoveMicrobiological"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :auto-upload="false"
                :multiple="false"
              >
                <el-button
                  size="small"
                  type="primary"
                >
                  {{ $t('global.browse') }}
                </el-button>
              </el-upload>
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('enums.commodityCategory.chemical')"
            >
              <el-button
                v-if="attachedFiles.chemical"
                size="small"
                type="primary"
                @click="removeFileAssociation(attachedFiles.chemical)"
              >
                {{ $t('global.removeSwitchPdf') }}
              </el-button>
              <el-upload
                v-else
                ref="chemicalUpload"
                action=""
                name="chemical"
                accept="application/pdf"
                :limit="1"
                :on-change="handleChangeChemical"
                :on-remove="handleRemoveChemical"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :auto-upload="false"
                :multiple="false"
              >
                <el-button
                  size="small"
                  type="primary"
                >
                  {{ $t('global.browse') }}
                </el-button>
              </el-upload>
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('enums.commodityCategory.vitro')"
            >
              <el-button
                v-if="attachedFiles.vitro"
                size="small"
                type="primary"
                @click="removeFileAssociation(attachedFiles.vitro)"
              >
                {{ $t('global.removeSwitchPdf') }}
              </el-button>
              <el-upload
                v-else
                ref="vitroUpload"
                action=""
                name="vitro"
                accept="application/pdf"
                :limit="1"
                :on-change="handleChangeVitro"
                :on-remove="handleRemoveVitro"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :auto-upload="false"
                :multiple="false"
              >
                <el-button
                  size="small"
                  type="primary"
                >
                  {{ $t('global.browse') }}
                </el-button>
              </el-upload>
            </FormItem>
          </el-col>
        </el-row>
        <FormButtons>
          <el-button
            type="secondary"
            @click.prevent="abortUpload"
          >
            {{ $t('orders.samples.abortUpload') }}
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(uploadReports)"
          >
            {{ $t('orders.samples.uploadReports') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { samplesApi, uploadsApi } from '@/api';
import FormItem from '@/components/FormItem.vue';
import ContentHeader from '@/components/ContentHeader.vue';
import FormButtons from '@/components/FormButtons.vue';

const defaultData = {
  microbiological: null,
  chemical: null,
  vitro: null,
};

export default {
  name: 'UploadReport',
  components: {
    FormItem,
    ContentHeader,
    FormButtons,
  },
  props: {
    visible: { type: Boolean, default: false },
    isModalEdit: { type: Boolean, default: false },
    initialData: { type: Object, default: () => defaultData },
  },
  data() {
    return {
      formData: {},
      attachedFiles: { ...defaultData },
      file: {
        microbiological: null,
        chemical: null,
        vitro: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'userPermissions',
    ]),
  },
  watch: {
    initialData: {
      handler(data) {
        if (Object.keys(data).length > 0) {
          this.attachedFiles = { ...data };
        }
      },
      deep: true,
    },
  },
  methods: {
    async uploadReports() {
      const filesUpload = await Promise.all(Object.keys(this.file).map(async (key) => {
        const fileDescription = {
          category: key,
          status: 'draft',
          sampleId: this.$route.params.sampleId,
          pdfFileId: null,
        };
        if (this.file[key]) {
          const stream = await this.file[key].raw.arrayBuffer();
          const upload = await uploadsApi.uploadFile(stream);
          fileDescription.pdfFileId = upload && upload.data ? upload.data.id : null;
        }
        return fileDescription;
      }));

      await Promise.all(filesUpload.map((file) => {
        if (file.pdfFileId) {
          return samplesApi.uploadSampleReport(
            this.$route.params.sampleId,
            file,
          );
        }
        return null;
      }));
      this.file = { ...defaultData };
      if (this.$refs.microbiologicalUpload) {
        this.$refs.microbiologicalUpload.clearFiles();
      }
      if (this.$refs.chemicalUpload) {
        this.$refs.chemicalUpload.clearFiles();
      }
      if (this.$refs.vitroUpload) {
        this.$refs.vitroUpload.clearFiles();
      }
      this.$emit('dialogClose', { success: true });
    },
    async removeFileAssociation(file) {
      try {
        await samplesApi.deleteSampleReport(file.sampleId, file.id);
        this.attachedFiles[file.category] = null;
        this.file[file.category] = null;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    abortUpload() {
      this.file = { ...defaultData };
      if (this.$refs.microbiologicalUpload) {
        this.$refs.microbiologicalUpload.clearFiles();
      }
      if (this.$refs.chemicalUpload) {
        this.$refs.chemicalUpload.clearFiles();
      }
      if (this.$refs.vitroUpload) {
        this.$refs.vitroUpload.clearFiles();
      }
      this.$emit('dialogClose', { success: false });
    },
    handleChange(file, category) {
      this.file[category] = file;
    },
    handleRemove(file, category) {
      this.file[category] = null;
    },
    handleExceed() {
      this.$notify.warning(this.$t('global.noMultipleFilesUpload'));
    },
    beforeRemove() {
      return this.$confirm(this.$t('global.confirmUploadCancel'));
    },
    // Single file upload handlers
    handleChangeMicrobiological(file) {
      this.handleChange(file, 'microbiological');
    },
    handleRemoveMicrobiological(file) {
      this.handleRemove(file, 'microbiological');
    },
    handleChangeChemical(file) {
      this.handleChange(file, 'chemical');
    },
    handleRemoveChemical(file) {
      this.handleRemove(file, 'chemical');
    },
    handleChangeVitro(file) {
      this.handleChange(file, 'vitro');
    },
    handleRemoveVitro(file) {
      this.handleRemove(file, 'vitro');
    },
  },
};
</script>
